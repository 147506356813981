import type { SchemeDbClass } from '@shared/types-v0.1.0'
import type { Notification } from '../component/handleSubscribe'
import type { BaseProps, Props } from './definition'

// Типизация уточняется поочереди Store, в нем используется Subscribes и SchemeData, затем типизируем их и т.д.

// Кэш параметров, чтобы отличать старые от прилетевших новых данных, сравнивать их и принимать решения
export default (p: Props) =>
	({
		rootId: R.libs.nanoid(8),
		inited: false,
		signalsInited: false,
		apiVersion: p.apiVersion,
		fetchScheme: p.fetchScheme,
		controlled: p.controlled,
		subscribe: p.subscribe,
		paginationOn: p.paginationOn,
		paginationClass: p.paginationClass,
		toPageNumber: p.toPageNumber,
		page: 1,
		totalPagesCount: 0,
		from: 0,
		size: 0,
		schemesData: [],
		subscribes: new Map(),
	}) satisfies Store

export type Store = BaseProps & {
	rootId: string
	inited: boolean
	signalsInited: boolean
	schemesData: SchemeData[]
	subscribes: Subscribes
	page: number
	totalPagesCount: number
	from: number
	size: number
}

type Subscribes = Map<string, { channel: string; notify: (notif: Notification) => void }>

export type SchemeData = {
	scheme: FetchScheme
	schemeHash: string
	path: string
	level: number
	parentId: string
	itemIds: string[]
	fetched: number
	total: number
	aggregations?: { [x: string]: any }
	channel?: string
}

export type FetchScheme = {
	dbName?: string
	dbClass: SchemeDbClass
	size?: number
	filters?: Record<string, unknown>
	sorts: readonly { [path: string]: 'asc' | 'desc' }[]
	history?: number
}
