import { merge } from '@shared/item-v0.1.0'
import type { Item } from '@shared/types-v0.1.0'
import getIem from './getIem'

export default (rawItem: Item, rootId: string) => {
	// Находим item в R.items
	const proxyItem = R.items[rawItem.id]

	// Прокси - это распарсеный объект, который имеет влдоженные объекты, до уровня, пока не
	// создадутся прокси для каждого вложенного объекта. Это позволяет подписываться
	// на изменение значения конкретного поля.

	// Если прокси item уже есть в R.items, то мержим
	// - то есть обновляем поля прокси объектов в R.items, так как если мы просто заменим объект, а не смержим,
	// то не корректно сработают подписки на изменение полей прокси объекта
	if (proxyItem) merge({ object: rawItem, proxyObject: proxyItem })
	// Если нет, то добавим в R.items (обавим методы и встроенные параметры.)
	else R.items[rawItem.id] = getIem(rawItem, rootId)
}
